import React from 'react'
import EveningComponents from "../pages/homre/EveningComponents"
import Navbar from '../Components/Navbar'

const Evening = () => {
  return (
    <div
    style={{
      marginTop:"9rem",
    }}
    >
    <Navbar/>
    <EveningComponents/>
    </div>
  )
}

export default Evening