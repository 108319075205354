import React from 'react'
import NightComponent from '../pages/homre/NightComponent'
import Navbar from '../Components/Navbar'

const Night = () => {
  return (
    <div 
    style={{
      marginTop:"9rem",
    }}
    >
      <Navbar/>
    <NightComponent/>
    </div>
  )
}

export default Night