import React from 'react'
import MorningComponent from '../pages/homre/MorningComponent'
import Navbar from '../Components/Navbar'

const Morning = () => {
  return (
    <div
    style={{
      marginTop:"9rem",
    }}
    >
        <Navbar/>
        <MorningComponent/>

    </div>
  )
}

export default Morning